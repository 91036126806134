<template>
    <v-app>
        <v-main class="pa-10">
            <v-row class="mb-3">
                <v-col cols="3" class="text-h5">
                    Ducts File System Viewer
                </v-col>
                <!--<v-col>
                    <v-icon class="pr-3" @click.stop="hoge();">mdi-information-outline</v-icon>
                    <v-icon class="pr-3" @click.stop="hoge();">mdi-pencil</v-icon>
                    <v-icon class="pr-3" @click.stop="hoge();">mdi-delete</v-icon>
                    <v-icon class="pr-3" @click.stop="hoge();">mdi-download</v-icon>
                </v-col>-->
            </v-row>
            <v-expansion-panels>
                <v-expansion-panel
                    v-for="group in groupEntries"
                    :key="group[0]"
                    >
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col cols="2">
                                <b>{{ group[1].metadata.group_key_text }} ({{ Object.keys(group[1].contents).length }})</b>
                            </v-col>
                            <v-col cols="3">
                                <span class="text-caption">{{ group[1].metadata.group_key }}</span>
                            </v-col>
                            <v-col>
                                <v-icon dense class="mr-3" @click.stop="openDialogMetadata(group[0]);">mdi-information-outline</v-icon>
                                <v-icon dense class="mr-3" @click.stop="hoge();">mdi-pencil</v-icon>
                                <v-icon dense class="mr-3" @click.stop="hoge();">mdi-delete</v-icon>
                                <v-icon dense class="mr-3" @click.stop="hoge();">mdi-download</v-icon>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="content in Object.entries(group[1].contents)"
                                :key="group[0]+'::'+content[0]"
                                >
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col cols="2">
                                            <b>{{ content[1].content_key_name }}</b>
                                            &nbsp;
                                            &nbsp;
                                            <span class="text-caption">({{ convertByteSize(content[1].content_length) }})</span>
                                        </v-col>
                                        <v-col cols="3">
                                            <span class="text-caption">{{ content[0] }}</span>
                                        </v-col>
                                        <v-col>
                                            <v-icon dense class="mr-3" @click.stop="openDialogMetadata(group[0], content[0]);">mdi-information-outline</v-icon>
                                            <v-icon dense class="mr-3" @click.stop="hoge();">mdi-pencil</v-icon>
                                            <v-icon dense class="mr-3" @click.stop="hoge();">mdi-delete</v-icon>
                                            <v-icon dense class="mr-3" @click.stop="download(group[0],content[0],content[1].content_key_name,content[1].content_type);">mdi-download</v-icon>
                                            <v-icon dense class="mr-3" @click.stop="hoge();">mdi-eye</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-dialog v-model="dialogMetadata" max-width="600">
                <v-card>
                    <v-card-title>
                        Metadata Info
                    </v-card-title>
                    <v-simple-table dense>
                        <tbody>
                            <tr v-for="c in dialogMetadataContent" :key="'dialog-metadata-'+c[0]">
                                <th>{{ c[0] }}</th>
                                <td v-if="['created', 'last_modified'].includes(c[0])">{{ formatDate(c[1]) }}</td>
                                <td v-else-if="c[0]=='content_length'">{{ convertByteSize(c[1]) }}</td>
                                <td v-else>{{ c[1] }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>
<script>
import { YhtDuct } from '@/libs/yht'
import { saveAs } from 'file-saver'

export default {
    data: () => ({
        duct: new YhtDuct(),
        groups: {},
        dialogMetadata: false,
        dialogMetadataContent: [],
    }),
    computed: {
        groupEntries() {
            return Object.entries(this.groups);
        }
    },
    methods: {
        openDialogMetadata(groupKey, contentKey) {
            if(contentKey){
                this.dialogMetadataContent = Object.entries(this.groups[groupKey].contents[contentKey]);
            } else  {
                this.dialogMetadataContent = Object.entries(this.groups[groupKey].metadata);
            }
            this.dialogMetadata = true;
        },
        formatDate(d) {
            if(typeof(d)==='string') d = parseInt(d);
            if(typeof(d)==='number') {
                if(d<10**10) d *= 1000;
                d = new Date(d);
            }
            function padZeros(num) { return ('00'+num).slice(-2); }
            const [year, month, date_, hours, minutes, seconds] = [d.getFullYear(), d.getMonth()+1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()];
            return `${year}-${padZeros(month)}-${padZeros(date_)} ${padZeros(hours)}:${padZeros(minutes)}:${padZeros(seconds)}`
        },
        convertByteSize(size) {
            if(typeof(size)==='string') size = parseInt(size);
            if(size>=1024**3) return (size/(1024**3)).toFixed(2)+'GiB';
            else if(size>=1024**2) return (size/(1024**2)).toFixed(2)+'MiB';
            else if(size>=1024**1) return (size/(1024**1)).toFixed(2)+'KiB';
            else return size.toFixed(2)+'B';
        },
        async download(groupKey, contentKey, contentKeyName, contentType) {
            const queue = await this.duct.call(
                this.duct.EVENT.BLOBS,
                [ groupKey, contentKey ]
            );
            let content = new Uint8Array([])
            for(;;) {
                const _content = await queue.dequeue();
                if(_content){ content = new Uint8Array([ ...content, ..._content ]); }
                else { break; }
            }
            saveAs(new Blob([content], { type: contentType }), contentKeyName);
        }
    },
    created() {
        this.duct.open("/ducts/wsd");
        this.duct.invokeOnOpen(async () => {
            let groups = {};
            const groupKeys = await this.duct.call(this.duct.EVENT.BLOBS_GROUP_LIST);
            console.log(groupKeys);
            for (const gi in groupKeys){
                const groupKey = groupKeys[gi];
                const gMetadata = await this.duct.call(
                    this.duct.EVENT.BLOBS_GROUP_METADATA,
                    [groupKey]
                );

                const contentKeys = await this.duct.call(
                    this.duct.EVENT.BLOBS_CONTENT_LIST,
                    [groupKey]
                );

                let cMetadata = {};
                for(const ci in contentKeys){
                    const key = contentKeys[ci];
                    const meta = await this.duct.call(
                        this.duct.EVENT.BLOBS_CONTENT_METADATA,
                        [groupKey, key]
                    );
                    cMetadata[key] = { ...meta[1], created: new Date(parseInt(meta[0].split('-')[0])) };
                }

                groups[groupKey] = { metadata: { ...gMetadata[1], created: new Date(parseInt(gMetadata[0].split('-')[0])) }, contents: cMetadata }
            }
            this.groups = groups
            console.log(this.groups);
        });
    }
}
</script>
